@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');



:root {
  --primary-color: #000000;
  --secondary-color: #FF2A2A;
  --tertiary-color: #ECEBF3;
}

a {
  text-decoration: none;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar, 
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
body, html {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  background-color: var(--primary-color);

}

.App {
  background-color: var(--primary-color);
  min-height: 100vh;
  overflow: hidden;
  transition: color 0.5s ease;
}



/* 3JS Canvas */

canvas{
  width: 100% !important;
  height: 100% !important;
  z-index: -1;

}

.cube-container {
  max-width: 300px; /* Fixed size */
  margin-left: auto;
  position: relative; 
  z-index: 0;
  overflow: visible; /* Adjust as needed */
}

.scale-on-hover {
  transition: transform 0.3s ease-in-out;
  transform-origin: center;
  transform: scale(10); /* Default scale */
  z-index: -1;
}


.hover-block{
  margin-left: 500px;
  position: absolute;
  width: 300px;
  height: 300px;
  z-index: 3;
  display: hidden;
}

/* All Sections */

.main-container {
  position: relative;
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  scroll-padding-top: 0;
  -webkit-overflow-scrolling: touch;
}

.main-container::-webkit-scrollbar {
  display: none;
}

section {
  scroll-snap-align: end;
  height: calc(100vh + 20px);
  scroll-padding-top: 60px; /* Adjust the value based on your navbar height */
  scroll-margin-top: 1vh; /* Adjust the value as needed */

}

#section-title{
  font-family: 'Bebas Neue', cursive;
  font-weight: 200;
  font-size: 100px;
  color: var(--tertiary-color);
  line-height: 0.5;
  margin: 0;
}

.section-arrow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to top, var(--secondary-color) 50%, var(--tertiary-color) 50%);
  background-size: 100% 200%;
  transition: background-position 0.5s ease, color 0.5s ease;
  background-position: 0% 100%;
  width: 100vw;
  text-align: center;
  bottom: 0; /* Indicates where the scroll will "stick" */
  height: 150px;
  flex-direction: column;
  position: sticky;
  z-index: 1;
}

.section-arrow-container p{
  font-family: 'Inconsolata', monospace !important;
  font-weight: 700;
  font-size: 13px;
  color: var(--tertiary-color);
  max-width: 600px;
  transition: color 0.5s ease;
}


.section-arrow-container.at-bottom {
  position: fixed;
  bottom: 0;
}


.section-arrow-container:hover{
  background-position: 0% 0%;
  background-image: linear-gradient(to top, var(--secondary-color) 50%, var(--tertiary-color) 50%);
}

.section-arrow-container:hover p{
  color: var(--secondary-color); 
}

.section-arrow-container:hover .down-arrow {
  color: var(--secondary-color); 
}

/* Logo & Navbar */
.navbar-links{
  z-index: 3;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  height: 15vh;
  z-index: 3;
}

.navbar-logo-wrapper {
  height: 80px;
  padding: 20px;
  margin-left: 30px;
  pointer-events: auto;
  transition: transform 0.5s ease-in-out;
  cursor: pointer;  /* Changes the cursor to a hand when you hover over the logo */
  z-index: 3;

}

.navbar-logo {
  fill: var(--secondary-color);
  height: 100%;
  animation: shake 5s infinite;
  z-index: 3;
}

.navbar-logo:active {
  transform: scale(0.8); /* This will make the logo 80% of its size when clicked */
  animation: none;
  transition: transform 0.2s ease-in-out;
  pointer-events: none; /* Add this line */
}

.navbar-logo-wrapper.pop {
  transform: scale(1.2); /* This will make the logo 120% of its size when popping back */
  transition: transform 0.5s ease-in-out;
  animation: none;

}

@keyframes shake {
  19% { transform: none; }
  20% { transform: scale(1.05) rotate(5deg); }
  25% { transform: scale(1.05) rotate(-5deg); }
  30% { transform: scale(1.05) rotate(5deg); }
  35% { transform: none; }
}

@keyframes pop {
  0% { transform: scale(1); } 
  50% { transform: scale(1.2); } 
  100% { transform: scale(1); }
}




.navbar-links {
  list-style: none;
  display: flex;
  align-items: center;
  font-family: 'Bebas Neue', cursive;
  padding: 0;
  margin: 0;
}


.navbar-links li {
  margin-left: 30px;
  margin-right: 30px;
}

.navbar-links li a {
  text-decoration: none;
  font-family: 'Bebas Neue', cursive;
  font-size: 20px;
  color: var(--secondary-color);
}

.navbar-links li:last-child a {
  color: var(--tertiary-color);
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #f1f1f1}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown {
  display: none;
}

.menu-button{
  margin-right: 30px;
  color: var(--secondary-color);
  font-size: 25px;
}

@media screen and (max-width: 850px) {
  .navbar-links {
    display: none;
  }
  .dropdown {
    display: block;
  }
}


/* Home */
#home {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 85vh !important;
  width: 100vw;
}

.section-info{
  display: flex;
  align-items: center;
  justify-content: right;
}

.info-text{
  font-family: 'Inconsolata', monospace !important;
  font-weight: 700;
  font-size: 14px !important;
  color: var(--secondary-color) !important;
}

.home-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.home-text {
  margin-right: 5vw;
  flex: 1;
  z-index: 3;
}

.home-text p {
  font-family: 'Bebas Neue', cursive;
  font-size: 20px;
  color: var(--secondary-color);
  margin: 0;
  padding-left: 5px;
  line-height: 1.5;
}

.home-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.home-list li {
  font-family: 'Bebas Neue', cursive;
  font-size: 100px;
  color: var(--secondary-color);
  margin: 0;
  font-weight: 200;
  line-height: 0.9;
  transition: color 0.5s ease;
}

.home-list li:last-child {
  color: var(--tertiary-color);
}



.down-arrow{
  color: var(--tertiary-color);
  padding-top: 10px;
  transition: color 0.5s ease;
}

.about-us:hover{
  background-position: 0% 0%;
  background-image: linear-gradient(to top, var(--secondary-color) 50%, var(--tertiary-color) 50%);
}

.about-us:hover p{
  color: var(--secondary-color);
}

.about-us:hover .down-arrow {
  color: var(--secondary-color); 
}

/* Services */

#services {
  display: flex;
  min-height: 100vh;

  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}

.service-num{
  margin-left: 30px;
  font-family: 'Inconsolata', monospace !important;
  padding: none;
  margin-bottom: 0;
  margin-top: 0;


}

.services p{
  font-family: 'Inconsolata', monospace !important;
  font-weight: 700;
  font-size: 13px;
  color: var(--tertiary-color);
}



.services-cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100vw;
  padding: 20px;
  text-align: left;
}

#services-content{
  height: 62vh;
}

.service-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  height: 335px;
  width: 230px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 0px;
  margin-left: 25px;
  margin-right: 25px;

  margin-top: 10px;

  text-align: center;
  border-radius: 10px;
  background-color: var(--secondary-color);
}


.service-card h3 {
  margin-top: 40px;
  font-family: 'Bebas Neue', cursive;
  font-size: 25px;
  color: var(--tertiary-color);
  font-weight: 200;
  line-height: 0.9;
}

.service-card p {
  margin-top: 10px;
  font-family: 'Inconsolata', monospace !important;
  font-weight: 700;
  font-size: 13px;
  color: var(--tertiary-color);
}

.service-icons{
  color: var(--tertiary-color);
}

.service-card:hover {
  background-color: var(--tertiary-color);
}

.service-card:hover h3{
  color: var(--secondary-color);
}

.service-card:hover p{
  color: var(--secondary-color);
}

.service-card:hover .service-icons{
  color: var(--secondary-color);
}



/* Products */
#products {
  display: flex;
  min-height: 100vh;

  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}

.products p{
  font-family: 'Inconsolata', monospace !important;
  font-weight: 700;
  font-size: 13px;
  color: var(--tertiary-color);
}

#products-content{
  height: 62vh;
  justify-content: center;

}
.product-grid {
  display: grid;
  grid-template-columns: 1fr 2fr; 
  gap: 10px;
  margin-top: 35px;
}

.product-grid a{
  font-family: 'Inconsolata', monospace !important;
  font-weight: 700;
  font-size: 13px;
  color: var(--tertiary-color);
  text-decoration-line: underline;
}

.product-item {
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 45px;
  width: 220px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.product-item-2 {
  display: flex;
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 40px;
  width: 500px;
  text-align: center;
}

.product-text{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.product-item h3 {
  font-family: 'Tahoma', monospace;
  font-size: 30px;
  color: var(--tertiary-color);
  font-weight: 550;
  line-height: 0.9;
  margin: 0; 
}

.product-item-2 h3 {
  margin-top: 20px;
  font-family: 'Bebas Neue', cursive;
  font-size: 30px;
  color: var(--tertiary-color);
  font-weight: 200;
  line-height: 0.9;
  margin-bottom: 5px; 
}

.product-column {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
}

.product-item-2 p{
  width: 70%;
}


.product-img {
  width: 30%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#works{
  display: flex;
  min-height: 100vh;

  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}

.works p{
  font-family: 'Inconsolata', monospace !important;
  font-weight: 700;
  font-size: 13px;
  color: var(--tertiary-color);
}

#works-content{
  height: 62vh;
}

.yt-vid{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--secondary-color);
  border-radius: 10px;
  text-align: left;
  padding-bottom: 10px;
  margin-top: 0px;
  margin-left: 10px;
  margin-right: 10px;
}

.yt-vid p{
  margin-left: 10px;
}

.work-posts{
  display: flex;
  justify-content: center;
}

.work-links{
  display: flex;
  justify-content: center;
}

.work-link{
  margin: 30px;
  font-family: 'Inconsolata', monospace !important;
  font-weight: 700;
  font-size: 15px;
  color: var(--tertiary-color);
  text-decoration-line: underline;

}

#contact{
  display: flex;
  min-height: 100vh;

  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}

#contact-content{
  height: 62vh;
  width: 100%;
}

.contact p{
  font-family: 'Inconsolata', monospace !important;
  font-weight: 700;
  font-size: 13px;
  color: var(--tertiary-color);
}

.contact-form{
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-right: 20px;
}

.form-input{
  margin-top: 15px;
  margin-bottom: 10px;
  height: 140px;
  width: 350px;
}

.email-input{
  margin-top: 15px;
  margin-bottom: 10px;
  height: 30px;
}


.form-label{
  font-family: 'Inconsolata', monospace !important;
  font-weight: 700;
  font-size: 15px;
  color: var(--tertiary-color);
}

.submit-button{
  margin-top: 10px;
  font-family: 'Inconsolata', monospace !important;
  font-weight: 700;
  font-size: 15px;
  color: var(--tertiary-color);
  background-color: var(--secondary-color);
  height: 30px;
}

.contact-elements{
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.contact-line{
  border-left: 2px solid  var(--secondary-color);
  height: 300px;
  margin-top: 70px;
}
.contact-team{
  margin-left: 45px;
  margin-top: 45px;
  text-align: left;
}

.contact-team h3{
  font-family: 'Bebas Neue', cursive;
  font-size: 47px;
  color: var(--tertiary-color);
  font-weight: 200;
  line-height: 0.0;
  margin-bottom: 20px;
  margin-top: 25px;

}

.contact-team p{
  font-family: 'Inconsolata', monospace !important;
  font-weight: 700;
  font-size: 15px;
  color: var(--tertiary-color);
}


.contact-member{
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding-left: 20px;
  padding-top: 20px;
  margin-bottom: 15px;
  width: 340px;
  height: 110px;
}

.member-social{
  color: var(--tertiary-color);
  font-size: 23px;
  margin: 0px;
  padding-right: 10px;
}

.member-social:hover{
  transform: scale(1.2);
}

.contact-member p{
  font-family: 'Inconsolata', monospace !important;
  font-weight: 700;
  font-size: 13px;
  color: var(--tertiary-color);
}

.section-arrow-container-last {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to top, var(--secondary-color) 50%, var(--tertiary-color) 50%);
  background-size: 100% 200%;
  transition: background-position 0.5s ease, color 0.5s ease;
  background-position: 0% 100%;
  width: 100vw;
  text-align: center;
  bottom: 0; /* Indicates where the scroll will "stick" */
  height: 150px;
  flex-direction: column;
  position: sticky;
  z-index: 1;
}

.section-arrow-container-last p{
  font-weight: 700;
  font-size: 13px;
}

.social-row{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.contact-social{
  color: var(--tertiary-color);
  font-size: 35px;
  margin: 0px;
  padding-right: 20px;
  padding-left: 20px;

}

.contact-social:hover{
  transform: scale(1.2);
}

.mobile-message{
  position: fixed; /* or 'absolute' if you want it relative to a positioned ancestor */
  top: 0;
  left: 0;
  width: 100vw;   /* 100% of the viewport width */
  height: 100vh;  /* 100% of the viewport height */
  z-index: 9999;
  background-color: var(--secondary-color);
  align-items: center;
  justify-content: center;
  text-align: center;
  display: none;
}

.mobile-message p{
  color: var(--primary-color);
  width: 80%;
  font-family: 'Inconsolata', monospace !important;
  font-weight: 700;
  font-size: 13px;
}


@media screen and (max-width: 1350px) {
  .work-posts{
    transform: scale(0.8);
  }
}

@media screen and (max-width: 1090px) {
  .services-cards{
    transform: scale(0.7);
    flex-wrap: nowrap;

  }
  .work-posts{
    transform: scale(0.7);
  }

  .product-grid{
    transform: scale(0.7);
  }
}

@media screen and (max-width: 950px) {
  .vid3{
    display: none;
  }

  .work-links, .contact-elements{
    transform: scale(0.7);
  }

}

@media screen and (max-width: 670px) {
  .mobile-message{
    display: flex;
  }
}